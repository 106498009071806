import { useEffect } from 'react';
import logo from '../assets/amicaLogo.svg';
import '../styles/navbar.scss';
// import useScrollDirection from '../customHooks/useScrollDirection';
// import useScrollPosition from '../customHooks/useScrollPosition';
// import useWindowSize from '../customHooks/useWindowSize';
import { motion } from 'framer-motion';

const Navbar = ({ navLocation }) => {
  // let scrollDirection = useScrollDirection();
  // let scrollPosition = useScrollPosition();
  // const windowSize = useWindowSize();
  // const [classNames, setClassNames] = useState('navbar hidden');
  // let descripRef = useRef(null);

  // useEffect(() => {
  //   if (scrollPosition >= windowSize.height && scrollDirection === 'down') {
  //     setClassNames('navbar  show');
  //   }

  //   if (scrollPosition <= windowSize.height) {
  //     setClassNames('navbar hidden');
  //   }
  // }, [scrollDirection, scrollPosition, windowSize.height]);

  useEffect(() => {
    navData.map((link) => {
      if (navLocation === link.id) {
        link.active = true;
        // link.ref.current.color = '#00a88f';
      } else {
        link.active = false;
      }
    });
  }, [navLocation]);

  const navData = [
    {
      id: 1,
      href: '#description',
      text: 'Our Story',
      secondary: ''
    },
    {
      id: 2,
      href: '#theRecipeBook',
      text: 'The Recipe Book',
      active: false,
      secondary: ''
    },
    {
      id: 3,
      href: '#theCamera',
      text: 'The Camera',
      active: false,
      secondary: ''
    },
    {
      id: 4,
      href: '#theDrum',
      text: 'The Drum Set',
      secondary: ''
    },

    {
      id: 5,
      href: '#event',
      text: 'The Legacy Room',
      secondary: ''
    },
    {
      id: 6,
      href: '#theStories',
      text: 'The Stories',
      secondary: ''
    },

    {
      id: 7,
      href: '#cta',
      text: 'Learn More',
      secondary: ''
    }
  ];

  return (
    <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{ duration: 1 }}
      id="navbar"
      className="navbar"
    >
      <div className="navbar__logoCont">
        <img src={logo} alt="logo lockup" />
      </div>

      <div className="navbar__linksCont">
        {navData.map((link) => (
          <div key={link.id} className="navbar__linksCont__link" id={link.id} ref={link.ref}>
            <a href={link.href} className={navLocation === link.id ? 'active' : ''}>
              {link.text}
            </a>
          </div>
        ))}
      </div>
    </motion.div>
  );
};

export default Navbar;
