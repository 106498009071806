import React, { useEffect, useState, useRef } from 'react';
import { useInView } from 'react-intersection-observer';
import Tile from './Tile';
import '../../styles/theStories.scss';
import anneAudio from '../../assets/audioFiles/anne.wav';
import bennettAudio from '../../assets/audioFiles/bennett.wav';
import catharineAudio from '../../assets/audioFiles/catharine.wav';
import johnAudio from '../../assets/audioFiles/john.wav';
import jonathonAudio from '../../assets/audioFiles/jonathon.wav';
import laurenAudio from '../../assets/audioFiles/lauren.wav';
import rashunaAudio from '../../assets/audioFiles/rashuna.wav';
import rhondaAudio from '../../assets/audioFiles/rhonda.wav';

const TheStoriesMain = ({ setNavLocation }) => {
  const { ref, inView } = useInView({ threshhold: [0.5, 0.9] });
  let [personPlaying, setPersonPlaying] = useState('');
  let [isPlaying, setIsPlaying] = useState(false);

  const nameToAudio = {
    Anne: new Audio(anneAudio),
    Bennett: new Audio(bennettAudio),
    Catharine: new Audio(catharineAudio),
    John: new Audio(johnAudio),
    Jonathon: new Audio(jonathonAudio),
    Lauren: new Audio(laurenAudio),
    Rashuna: new Audio(rashunaAudio),
    Rhonda: new Audio(rhondaAudio)
  };
  const AnneRef = useRef(nameToAudio['Anne']);
  const BennettRef = useRef(nameToAudio['Bennett']);
  const CatharineRef = useRef(nameToAudio['Catharine']);
  const JohnRef = useRef(nameToAudio['John']);
  const JonathonRef = useRef(nameToAudio['Jonathon']);
  const LaurenRef = useRef(nameToAudio['Lauren']);
  const RashunaRef = useRef(nameToAudio['Rashuna']);
  const RhondaRef = useRef(nameToAudio['Rhonda']);

  const nameToRef = {
    Anne: AnneRef,
    Bennett: BennettRef,
    Catharine: CatharineRef,
    John: JohnRef,
    Jonathon: JonathonRef,
    Lauren: LaurenRef,
    Rashuna: RashunaRef,
    Rhonda: RhondaRef
  };

  const handleClick = (name) => {
    setPersonPlaying(name);
    if (isPlaying) {
      nameToRef[personPlaying].current.pause();
      console.log(nameToAudio[name]);
      setIsPlaying(false);
      if (personPlaying !== name) {
        nameToRef[name].current.play();
        setIsPlaying(true);
      }
    } else {
      nameToRef[name].current.play();
      setIsPlaying(true);
    }
  };

  return (
    <div className="theStories" id="theStories" ref={ref}>
      <div className="theStories__introText">
        <p>THE</p>
        <p>Stories</p>
        <div className="horiLine" />
        <p>
          We asked you to share your stories of legacy—and what we heard moved us with its emotion
          and honesty. We hope you’ll explore below to hear some of these stories, and that they’ll
          inspire you to start a conversation with the people in your own life about the values that
          drive us, the impact we have on one another, and the way we can plan to leave behind
          something of true value.
        </p>
      </div>
      <div className="theStories__grid">
        <Tile
          index={0}
          personPlaying={personPlaying}
          setPersonPlaying={setIsPlaying}
          setIsPlaying={setIsPlaying}
          onClick={handleClick}
        />
        <Tile
          index={1}
          personPlaying={personPlaying}
          setPersonPlaying={setIsPlaying}
          setIsPlaying={setIsPlaying}
          onClick={handleClick}
        />
        <Tile
          index={2}
          personPlaying={personPlaying}
          setPersonPlaying={setIsPlaying}
          setIsPlaying={setIsPlaying}
          onClick={handleClick}
        />
        <Tile
          index={3}
          personPlaying={personPlaying}
          setPersonPlaying={setIsPlaying}
          setIsPlaying={setIsPlaying}
          onClick={handleClick}
        />
        <Tile
          index={4}
          personPlaying={personPlaying}
          setPersonPlaying={setIsPlaying}
          setIsPlaying={setIsPlaying}
          onClick={handleClick}
        />
        <Tile
          index={5}
          personPlaying={personPlaying}
          isPlaying={isPlaying}
          setPersonPlaying={setIsPlaying}
          setIsPlaying={setIsPlaying}
          onClick={handleClick}
        />
        <Tile
          index={6}
          personPlaying={personPlaying}
          setPersonPlaying={setIsPlaying}
          setIsPlaying={setIsPlaying}
          onClick={handleClick}
        />
        <Tile
          index={7}
          personPlaying={personPlaying}
          setPersonPlaying={setIsPlaying}
          setIsPlaying={setIsPlaying}
          onClick={handleClick}
        />
      </div>
    </div>
  );
};

export default TheStoriesMain;
