import '../styles/footer.scss';

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer__links">
        <a href="https://wbd.com/visitor-agreement/" target="_blank" rel="noreferrer">
          Terms of Service
        </a>
        <a
          href="https://wbd.com/privacy-notice/"
          target="_blank"
          rel="noreferrer"
          style={{ marginLeft: '2rem' }}
        >
          Privacy Policy
        </a>
      </div>
    </div>
  );
};

export default Footer;
