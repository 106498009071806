import photo1 from '../../../assets/recipeGallery1.jpg';
import photo2 from '../../../assets/recipeGallery2.jpg';
import photo3 from '../../../assets/recipeGallery3.jpg';
import photo4 from '../../../assets/recipeGallery4.jpg';
import photo5 from '../../../assets/recipeGallery5.jpg';

//Update the url or delete below arrowImg if importing the arrows at the top
const arrowImg = 'https://component-library-assets.s3.amazonaws.com/arrow.svg';

const galleryContents = {
  fadeAnimation: false, //true if adding transition animation
  arrows: arrowImg, //import or assign path/url at the top
  contents: [
    {
      img: photo1,
      caption: 'After Skyy Madyun’s mother died, she forced herself to bury her grief.',
      disclaimer: ''
    },
    {
      img: photo2,
      caption:
        'With the discovery of an old book of recipes, she realized it was time to start coming to terms with what she had lost—by cooking her way through the recipes her mother had left behind for her.'
    },
    {
      img: photo3,
      caption:
        'Cooking from her mother’s recipe book, a beloved sour cream cake took on more meaning than Skyy ever imagined it could.',
      disclaimer: ''
    },
    {
      img: photo4,
      caption:
        'Today, for Skyy, the recipes in that book, and the joy that she’s found in making them for her friends and family, is a powerful reminder of her mother’s most closely-held values.'
    },
    {
      img: photo5,
      caption: ''
    }
  ]
};

export default galleryContents;
