import '../styles/carouselSection.scss';
import PhotoGallerySingle from './ArrowNav/Single/PhotoGallerySingle';

const CarouselSection = ({ navLocation, setNavLocation }) => {
  return (
    <div className="carouselSection">
      <PhotoGallerySingle />
      <div className="carouselSection__quoteCont"></div>
    </div>
  );
};

export default CarouselSection;
