import '../styles/carouselSection.scss';
import PhotoGallerySingle from './ArrowNavEvent/Single/PhotoGallerySingle';
// import quoteIcon from '../assets/greenQuoteIcon.svg';
// import ShareYourStory from '../components/ShareYourStory';
import TheStoriesMain from './theStories/TheStoriesMain';

const CarouselSectionEvent = ({ navLocation, setNavLocation }) => {
  return (
    <div className="carouselSection">
      <p style={{ marginTop: '4rem', fontFamily: 'apercuItalic' }}>
        Explore photos from the Legacy Room event
      </p>
      <PhotoGallerySingle />
      <div className="carouselSection__quoteCont">
        {/* <div className="carouselSection__quoteCont__imageCont">
          <img src={quoteIcon} alt="green quote icon" />
        </div> */}
        {/* <div className="carouselSection__quoteCont__textCont">
          <p>The process of being a photographer is kind of a conversation with my father.”</p>
        </div> */}
      </div>
      {/* <ShareYourStory setNavLocation={setNavLocation} navLocation={navLocation} /> */}
      <TheStoriesMain navLocation={navLocation} setNavLocation={setNavLocation} />
    </div>
  );
};

export default CarouselSectionEvent;
