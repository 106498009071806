import { useState, useRef, useEffect } from 'react';
import '../styles/storyTile.scss';
import { useInView } from 'react-intersection-observer';
import playIcon from '../assets/playIcon.svg';
import xButton from '../assets/xButton.svg';

const EventSizzleTile = ({ setNavLocation, navLocation }) => {
  let [popoutOpen, setPopoutOpen] = useState(false);
  const videoLoopRef = useRef();
  const { ref, inView } = useInView({ threshhold: [0.5, 0.9] });

  // useEffect(() => {
  //   videoLoopRef.current.play();
  // }, [videoLoopRef]);

  // useEffect(() => {
  //   if (inView) {
  //     setNavLocation(5);
  //   }
  // });

  return (
    <div className="storyTile" id="event" ref={ref}>
      <div className="storyTile__overlay" />
      <div className="storyTile__videoBG">
        <video
          autoPlay
          muted
          playsInline
          loop
          className="storyTile--videoLoop__video"
          ref={videoLoopRef}
        >
          <source
            media="(min-width:481px)"
            src="https://courageous-cdn-media-bucket.s3.amazonaws.com/amica/eventLoop.mp4"
            type="video/mp4"
          />
          <source
            media="(max-width:480px)"
            src="https://courageous-cdn-media-bucket.s3.amazonaws.com/amica/kwasiTileBgM.mp4"
            type="video/mp4"
          />
          Your browser does not support HTML5 video.
        </video>
      </div>
      <div className="storyTile__text">
        <div className="storyTile__text__header">
          <p>The</p>
          <p>Legacy Room</p>
        </div>
        <div className="storyTile__text__horiLine" />
        {/* <div className="storyTile__text__dateInfo">
          <p>Kwasi Boyd-Bouldin</p>
        </div> */}
        <div className="storyTile__text__pg">
          <p>
            On October 20th, Amica Insurance took the What You Leave Behind project to Boston with
            our Legacy Room installation: a unique opportunity for everyday people to share memories
            of their loved ones and the things they left behind. Soon, we’ll be posting some of the
            stories we heard here. We hope they’ll inspire you to share your own.
          </p>
        </div>
      </div>
      <div className="storyTile__button">
        <button onClick={() => setPopoutOpen(!popoutOpen)}>
          <p>Watch Video</p>
          <img src={playIcon} alt="play icon" />
        </button>
      </div>
      {popoutOpen && (
        <div className="storyTile__popout">
          <div className="storyTile__popout__closeButton">
            <button onClick={() => setPopoutOpen(!popoutOpen)}>
              <img src={xButton} alt="x with circle" />
            </button>
          </div>
          <div className="storyTile__popout__video">
            <img
              src="https://pt.ispot.tv/v2/TC-3412-1.gif?app=web&type=WBD_Video_Complete&"
              style={{ display: 'none' }}
              alt=""
            />
            <img
              src="https://pt.ispot.tv/v2/TC-3412-1.gif?app=web&type=WBD_Video_Start&"
              style={{ display: 'none' }}
              alt=""
            />
            <iframe
              src="https://player.vimeo.com/video/879879661?h=4497257a4b&amp;badge=0&amp;autoplay=1&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479"
              frameborder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              // src="https://player.vimeo.com/video/856856940?h=c2ea9d9965&amp;badge=0&amp;autoplay=1&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              className="storyTile__popout__video__player"
              title="fpo"
              // ref={videoRef}
              autoPlay="1"
            ></iframe>
          </div>
        </div>
      )}
    </div>
  );
};

export default EventSizzleTile;
