import '../styles/shareYourStory.scss';
import whatWillYouPhoto from '../assets/whatWillYouPhoto.jpg';

export const WhatWillYou = () => {
  return (
    <div
      className="shareYourStory paddingSpecial"
      id="whatWillYou"
      // style={{ backgroundColor: '#000', paddingTop: '10rem', paddingBottom: '8rem' }}
    >
      <div className="shareYourStory__content reverseCol">
        <div className="shareYourStory__text">
          <div className="shareYourStory__text__header">
            <p>WHAT WILL YOU</p>
            <p>Leave Behind?</p>
          </div>
          <div className="shareYourStory__text__horiLine" />
          {/* <div className="shareYourStory__text__dateInfo">
          <p>October 2023</p>
          <p>Boston, MA</p>
        </div> */}
          <div className="shareYourStory__text__pg">
            <p>
              A camera. A drum set. A gift of financial protection. At Amica Insurance, we
              understand that all of these represent enduring legacies of lives well lived—and that
              life insurance is a promise that our loved ones will be supported even when we’re no
              longer there to provide for them.
            </p>
            <p>
              But we also understand that taking the steps to leave something behind isn’t always
              easy.
            </p>
          </div>
        </div>
        <div className="shareYourStory__img marginTop">
          <img src={whatWillYouPhoto} alt="man and woman looking at object" />
        </div>
      </div>
    </div>
  );
};

export default WhatWillYou;
