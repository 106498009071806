import { useEffect } from 'react';
import '../styles/cta.scss';
import amicaLogo from '../assets/amicaLogo.svg';
import { useInView } from 'react-intersection-observer';

const CtaSection = ({ setNavLocation }) => {
  const { ref, inView } = useInView({ threshhold: 0.5 });

  // useEffect(() => {
  //   if (inView) {
  //     setNavLocation(7);
  //   }
  // });

  return (
    <div className="cta" id="cta" ref={ref}>
      <div className="cta__content">
        {/* <p className="cta__content__heading">What will you leave behind?</p> */}
        {/* <p>
          A camera. A drum set. A gift of financial protection. At Amica Insurance, we understand
          that all of these represent enduring legacies of lives well lived—and that life insurance
          is a promise that our loved ones will be supported even when we’re no longer there to
          provide for them.
        </p>
        <p>
          But we also understand that taking the steps to leave something behind isn’t always easy.
        </p> */}
        <p>Did you know:</p>
        <ul>
          <li>
            Nearly half of American households would encounter significant financial difficulties
            within six months of losing a primary wage earner.
          </li>
          <li>More than a quarter would reach this point in only one month. </li>
          <li>
            When it comes to life insurance, it’s estimated that more than 100 million Americans are
            either underinsured or completely uninsured.
          </li>
        </ul>
        <p className="specialText">
          Life insurance is more than a policy. It’s a legacy. And leaving it behind is an act of
          love, strength and courage.
        </p>
        <img src={amicaLogo} alt="amica logo lockup" />
        <a
          href="https://www.amica.com/landing-page/coverage/life.html?utm_source=crghub&utm_medium=sponsoredcontent&utm_campaign=courageous"
          target="blank"
          rel="noreferrer"
        >
          <button>Take the First Step</button>
        </a>
      </div>
    </div>
  );
};

export default CtaSection;
