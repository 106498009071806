import { useRef, useEffect } from 'react';
import '../styles/hero.scss';
import useWidthSetter from '../customHooks/useWidthSetter';
import scrollDownIcon from '../assets/scrollDownIcon.svg';

const Hero = () => {
  const videoLoopRef = useRef();
  const width = useWidthSetter();

  useEffect(() => {
    videoLoopRef.current.play();
  }, [videoLoopRef]);

  return (
    <>
      <div className="hero">
        {/* <div className="hero__overlay" /> */}
        <div className="hero__videoBG">
          <video
            autoPlay
            muted
            playsInline
            loop
            className="hero--videoLoop__video"
            ref={videoLoopRef}
          >
            {/* <source
              media="(min-width:721px)"
              src="https://courageous-cdn-media-bucket.s3.amazonaws.com/amica/heroBG.mp4"
              type="video/mp4"
            />
            <source
              media="(max-width:720px)"
              src="https://courageous-cdn-media-bucket.s3.amazonaws.com/amica/heroBGM.mp4"
              type="video/mp4"
            /> */}
            <source
              src={
                width >= 720
                  ? 'https://courageous-cdn-media-bucket.s3.amazonaws.com/amica/heroBG.mp4'
                  : 'https://courageous-cdn-media-bucket.s3.amazonaws.com/amica/heroBGM.mp4'
              }
              type="video/mp4"
            />
            Your browser does not support HTML5 video.
          </video>
        </div>
        {/* <div className="hero__img">
          <img src={heroLockup} alt="hero lockup" />
        </div> */}
        <div className="hero__scrollIcon">
          <img src={scrollDownIcon} alt="scroll down" />
        </div>
      </div>
    </>
  );
};

export default Hero;
