import { useEffect, useState } from 'react';
import './styles/App.scss';
import Header from './components/common-header/Header';
import Navbar from './components/Navbar';
import NavbarMobile from './components/NavbarMobile';
import Hero from './components/Hero';
import CarouselSection from './components/CarouselSection';
import CarouselSectionCamera from './components/CarouselSectionCamera';
// import OurStory from './components/OurStory';
import TheDrumTile from './components/TheDrumTile';
// import { ShareYourStory } from './components/ShareYourStory';
import CtaSection from './components/CtaSection';
import Description from './components/Description';
import useWidthSetter from './customHooks/useWidthSetter';
import Footer from './components/Footer';
import TheCameraTile from './components/TheCameraTile';
import WhatWillYou from './components/WhatWillYou';
import EventSizzleTile from './components/EventSizzleTile';
import CarouselSectionEvent from './components/CarouselSectionEvent';
import TheRecipeBookTile from './components/TheRecipeBookTile';
import CarouselSectionRecipe from './components/CarouselSectionRecipe';
// import TempProdDisplay from './components/TempProdDisplay';

function App() {
  const width = useWidthSetter();
  let [navLocation, setNavLocation] = useState(0);

  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, []);

  return (
    <div className="App">
      {/* <TempProdDisplay /> */}
      <Header />
      {width >= 1000 ? (
        <Navbar setNavLocation={setNavLocation} navLocation={navLocation} />
      ) : (
        <NavbarMobile />
      )}

      <Hero />
      {/* <OurStory /> */}
      <Description setNavLocation={setNavLocation} navLocation={navLocation} />
      <TheRecipeBookTile setNavLocation={setNavLocation} navLocation={navLocation} />
      <CarouselSectionRecipe />
      <TheCameraTile setNavLocation={setNavLocation} navLocation={navLocation} />
      <CarouselSectionCamera setNavLocation={setNavLocation} navLocation={navLocation} />
      <TheDrumTile setNavLocation={setNavLocation} navLocation={navLocation} />
      <CarouselSection setNavLocation={setNavLocation} navLocation={navLocation} />
      <EventSizzleTile setNavLocation={setNavLocation} navLocation={navLocation} />
      <CarouselSectionEvent setNavLocation={setNavLocation} navLocation={navLocation} />
      <WhatWillYou />
      <CtaSection setNavLocation={setNavLocation} navLocation={navLocation} />
      <Footer />
    </div>
  );
}

export default App;
