import '../styles/carouselSection.scss';
import PhotoGallerySingle from './ArrowNavRecipe/Single/PhotoGallerySingle';

const CarouselSectionCamera = () => {
  return (
    <div className="carouselSection">
      <PhotoGallerySingle />
      <div className="carouselSection__quoteCont">
        {/* <div className="carouselSection__quoteCont__imageCont">
          <img src={quoteIcon} alt="green quote icon" />
        </div> */}
        {/* <div className="carouselSection__quoteCont__textCont">
          <p>The process of being a photographer is kind of a conversation with my father.”</p>
        </div> */}
      </div>
    </div>
  );
};

export default CarouselSectionCamera;
