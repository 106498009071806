import '../styles/description.scss';
import { InView } from 'react-intersection-observer';

const Description = ({ setNavLocation }) => {
  // const { ref, inView } = useInView({ threshhold: 0 });

  // useEffect(() => {
  //   if (inView) {
  //     setNavLocation(1);
  //   }
  // }, [inView, setNavLocation]);

  return (
    // <InView as="div" onChange={(inView, entry) => setNavLocation(1)} threshold={[0.5, 0.9]}>
    <div className="description" id="description">
      <p>What will you leave behind?</p>
      <p>
        Everyone who’s lost a loved one knows that the smallest object can remind us of everything
        they stood for. Their values. Their dreams. Their impact on the world around them.
      </p>
      <p className="specialText">
        Whether it’s through objects and mementos or carefully laid financial plans, Amica believes
        that a legacy can take many forms. Leaving something meaningful starts with recognizing the
        things we inherit.
      </p>
      <p>
        That’s why, with the <span className="bold">What You</span>{' '}
        <span className="boldItalic">Leave Behind</span> project, we’re asking the world to start
        talking honestly about these legacies—and to begin considering our own.
      </p>
    </div>
    // </InView>
  );
};

export default Description;
