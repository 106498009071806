import { useState, useRef, useEffect } from 'react';
import '../styles/storyTile.scss';
import { useInView } from 'react-intersection-observer';
import playIcon from '../assets/playIcon.svg';
import xButton from '../assets/xButton.svg';

const TheDrumTile = ({ setNavLocation }) => {
  let [popoutOpen, setPopoutOpen] = useState(false);
  const videoLoopRef = useRef();
  const { ref, inView } = useInView({ threshhold: [0.5, 0.9] });

  // useEffect(() => {
  //   videoLoopRef.current.play();
  // }, [videoLoopRef]);

  // useEffect(() => {
  //   if (inView) {
  //     setNavLocation(4);
  //   }
  // });

  return (
    <div className="storyTile" id="theDrum" ref={ref}>
      <div className="storyTile__overlay" />
      <div className="storyTile__videoBG">
        <video
          autoPlay
          muted
          playsInline
          loop
          className="storyTile--videoLoop__video"
          ref={videoLoopRef}
        >
          <source
            media="(min-width:481px)"
            src="https://courageous-cdn-media-bucket.s3.amazonaws.com/amica/theDrumsLoop.mp4"
            type="video/mp4"
          />
          <source
            media="(max-width:480px)"
            src="https://courageous-cdn-media-bucket.s3.amazonaws.com/amica/theDrumsLoopM.mp4"
            type="video/mp4"
          />
          Your browser does not support HTML5 video.
        </video>
      </div>
      <div className="storyTile__text">
        <div className="storyTile__text__header">
          <p>The</p>
          <p>Drum Set</p>
        </div>
        <div className="storyTile__text__horiLine" />
        <div className="storyTile__text__dateInfo">
          <p>Chris Lotito</p>
        </div>
        <div className="storyTile__text__pg">
          <p>
            Growing up, Chris Lotito and his father, Alfred, spent hours bonding over a shared love
            of music. When they were together behind the 1966 Hollywood Ludwig drum set, it was like
            they were the only two people in the world. But not long after Chris lost his father to
            ALS, the drums were lost also. For the next twenty years, Chris searched for that set.
            Then, twenty years later—on Alfred’s birthday—Chris made an amazing discovery.
          </p>
        </div>
      </div>
      <div className="storyTile__button">
        <button onClick={() => setPopoutOpen(!popoutOpen)}>
          <p>Watch Video</p>
          <img src={playIcon} alt="play icon" />
        </button>
      </div>
      {popoutOpen && (
        <div className="storyTile__popout">
          <div className="storyTile__popout__closeButton">
            <button onClick={() => setPopoutOpen(!popoutOpen)}>
              <img src={xButton} alt="x with circle" />
            </button>
          </div>
          <div className="storyTile__popout__video">
            <img
              src="https://pt.ispot.tv/v2/TC-3412-1.gif?app=web&type=WBD_Video_Complete&"
              style={{ display: 'none' }}
              alt=""
            />
            <img
              src="https://pt.ispot.tv/v2/TC-3412-1.gif?app=web&type=WBD_Video_Start&"
              style={{ display: 'none' }}
              alt=""
            />
            <iframe
              src="https://player.vimeo.com/video/866091972?h=b3deb8dbc2&amp;badge=0&amp;autoplay=1&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              className="storyTile__popout__video__player"
              title="fpo"
              // ref={videoRef}
              autoPlay="1"
            ></iframe>
          </div>
        </div>
      )}
    </div>
  );
};

export default TheDrumTile;
