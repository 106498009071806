import React, { useRef, useState } from 'react';
import './PhotoGallerySingle.scss';
import galleryContents from './galleryContents';
import { AnimatePresence, motion } from 'framer-motion';
import PhotoGallerySingleMobile from './PhotoGallerySingleMobile';

function PhotoGallerySingle({ transitionAnimation }) {
  const contents = galleryContents.contents;
  const length = contents.length - 1;
  const [currentCenter, setCurrentCenter] = useState(0);
  const [currentLeft, setCurrentLeft] = useState(length);
  const [currentRight, setCurrentRight] = useState(1);
  const swipe = useRef();
  const transitionAnim =
    transitionAnimation !== null ? transitionAnimation : galleryContents.fadeAnimation;

  const clickHandleArrow = (direction) => {
    if (direction === 'left') {
      setCurrentRight(currentCenter);
      setCurrentCenter(currentLeft);
      setCurrentLeft(currentLeft === 0 ? length : currentLeft - 1);
    } else if (direction === 'right') {
      setCurrentLeft(currentCenter);
      setCurrentCenter(currentRight);
      setCurrentRight(currentRight === length ? 0 : currentRight + 1);
    } else {
      setCurrentCenter(direction);
      setCurrentLeft(direction === 0 ? length : direction - 1);
      setCurrentRight(direction === length ? 0 : direction + 1);
    }
  };

  const handleTouch = () => {
    if (swipe.current) {
      swipe.current.style.opacity = '0';
    }
  };

  return (
    <section className="galleryS" onTouchStart={handleTouch} onMouseEnter={handleTouch}>
      <div className="galleryS__content">
        <div className="galleryS__content__imgs--desktop">
          <img
            src={galleryContents.arrows}
            alt="left arrow"
            className="galleryS__arrow galleryS__arrow--left"
            onClick={() => {
              clickHandleArrow('left');
            }}
          />

          {transitionAnim ? (
            <AnimatePresence exitBeforeEnter initial={false}>
              <motion.div
                className="galleryS__content__imgs__img"
                key={currentCenter}
                initial={{
                  opacity: 0.2
                }}
                animate={{
                  opacity: 1
                }}
                exit={{
                  opacity: 0.2
                }}
                transition={{
                  opacity: { duration: 0.7 }
                }}
              >
                <div className="galleryS__content__imgs__img-container">
                  <img alt="" src={contents[currentCenter].img} className="galleryS__img" />
                  <p className="galleryS__disclaimer">{contents[currentCenter].disclaimer}</p>
                </div>
                <div className="galleryS__content__imgs__img-cap">
                  <p className="galleryS__caption">{contents[currentCenter].caption}</p>
                </div>
              </motion.div>
            </AnimatePresence>
          ) : (
            <div className="galleryS__content__imgs__img">
              <div className="galleryS__content__imgs__img-container">
                <img alt="" src={contents[currentCenter].img} className="galleryS__img " />
                <p className="galleryS__disclaimer">{contents[currentCenter].disclaimer}</p>
              </div>
              <div className="galleryS__content__imgs__img-cap">
                <p className="galleryS__caption">{contents[currentCenter].caption}</p>
              </div>
            </div>
          )}
          <img
            src={galleryContents.arrows}
            alt="right arrow"
            className="galleryS__arrow galleryS__arrow--right"
            onClick={() => {
              clickHandleArrow('right');
            }}
          />
        </div>
        <PhotoGallerySingleMobile contents={contents} swipe={swipe} />
      </div>
    </section>
  );
}

export default PhotoGallerySingle;
