import React, { useState, useRef } from 'react';
import tileData from './tileData';
import playAudioIcon from '../../assets/playAudioIcon.svg';
import stopAudioIcon from '../../assets/stopAudioIcon.svg';

const Tile = ({ index, personPlaying, setPersonPlaying, onClick }) => {
  const data = tileData[index];
  let [isPlaying, setIsPlaying] = useState(false);

  return (
    <div className="tile">
      <div className="tile__overlay" />
      <div className="tile__videoBG">
        <video autoPlay muted playsInline loop className="storyTile--videoLoop__video">
          <source media="(min-width:481px)" src={data.videoURL} type="video/mp4" />
          <source media="(max-width:480px)" src={data.videoURL} type="video/mp4" />
          Your browser does not support HTML5 video.
        </video>
      </div>
      <div className="tile__text">
        <p>THE</p>
        <p>{data.title}</p>
        <div className="horiLine" />
        <p>{data.person}</p>
      </div>
      <div className="tile__button">
        <button
          onClick={() => {
            setPersonPlaying(data.person);
            console.log(personPlaying);
            onClick(data.person);
            setIsPlaying(!isPlaying);
          }}
        >
          <p>{isPlaying && personPlaying === data.person ? 'Pause' : 'Play'} Audio</p>
          <div className="icon">
            <img
              src={isPlaying && personPlaying === data.person ? stopAudioIcon : playAudioIcon}
              alt="audio icon"
            />
          </div>
        </button>
      </div>
    </div>
  );
};

export default Tile;
