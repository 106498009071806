// uncomment below to import photos & arrow
// import photo1 from '../assets/photoGallery/photo1.png';
// import photo2 from '../assets/photoGallery/photo2.png';
// import photo3 from '../assets/photoGallery/photo3.png';
// import photo4 from '../assets/photoGallery/photo4.png';
// import photo5 from '../assets/photoGallery/photo5.png';
// import arrowImg from "../assets/arrow.svg"
import photo1 from '../../../assets/carousel1.jpg';
import photo2 from '../../../assets/carousel2.jpg';
import photo3 from '../../../assets/carousel3.jpg';
import photo4 from '../../../assets/carousel4.jpg';
import photo5 from '../../../assets/carousel5.jpg';

//Update the url or delete below arrowImg if importing the arrows at the top
const arrowImg = 'https://component-library-assets.s3.amazonaws.com/arrow.svg';

const galleryContents = {
  fadeAnimation: false, //true if adding transition animation
  arrows: arrowImg, //import or assign path/url at the top
  contents: [
    {
      img: photo1,
      caption:
        'Though Chris Lotito lost his father to ALS at an early age, his memory is still everywhere Chris looks.',
      disclaimer: ''
    },
    {
      img: photo2,
      caption: 'The 1966 Hollywood Ludwig drum set, first owned by Chris’s father, Alfred.'
    },
    {
      img: photo3,
      caption: 'For Chris, drumming is still a way of finding connection with his dad.',
      disclaimer: ''
    },
    {
      img: photo4,
      caption: 'Today, Chris’s daughter, an avid drummer, is continuing the family tradition.'
    },
    {
      img: photo5,
      caption: ''
    }
    // {
    //   img: photo6,
    //   caption: 'Half-frame self-portrait from Griffith Park courtesy of Kwasi Boyd-Bouldin.'
    // },
    // {
    //   img: photo7,
    //   caption: ''
    // }
  ]
};

export default galleryContents;
