import { useState } from 'react';
import '../styles/navbarMobile.scss';
import menuIcon from '../assets/menuIcon.svg';
import mobileLogo from '../assets/navbarMobileLogo.svg';
import amicaLogo from '../assets/amicaLogo.svg';
import xButton from '../assets/xButton.svg';

const NavbarMobile = () => {
  const [drawerOpen, setDrawerOpen] = useState();
  return (
    <div className="navMob">
      <div className="navMob__icon">
        <button onClick={() => setDrawerOpen(!drawerOpen)}>
          <img src={drawerOpen ? xButton : menuIcon} alt="three lines" />
        </button>
      </div>
      {drawerOpen && (
        <div className="navMob__drawer">
          <div className="navMob__drawer__logo">
            <img src={mobileLogo} alt="what you leave behind logo" />
          </div>
          <div className="navMob__drawer__links">
            <div className="mobNavlink">
              <a
                href="#ourStory"
                onClick={() => {
                  setDrawerOpen(!drawerOpen);
                }}
              >
                Our Story
              </a>
            </div>

            <div className="mobNavlink">
              <a
                href="#theRecipeBook"
                onClick={() => {
                  setDrawerOpen(!drawerOpen);
                }}
              >
                The Recipe Book
              </a>
            </div>
            <div className="mobNavlink">
              <a
                href="#theCamera"
                onClick={() => {
                  setDrawerOpen(!drawerOpen);
                }}
              >
                The Camera
              </a>
            </div>
            <div className="mobNavlink">
              <a
                href="#theDrum"
                onClick={() => {
                  setDrawerOpen(!drawerOpen);
                }}
              >
                The Drum Set
              </a>
            </div>

            <div className="mobNavlink">
              <a
                href="#event"
                onClick={() => {
                  setDrawerOpen(!drawerOpen);
                }}
              >
                The Legacy Room
              </a>
            </div>
            <div className="mobNavlink">
              <a
                href="#theStories"
                onClick={() => {
                  setDrawerOpen(!drawerOpen);
                }}
              >
                The Stories
              </a>
            </div>
            <div className="mobNavlink">
              <a
                href="#cta"
                onClick={() => {
                  setDrawerOpen(!drawerOpen);
                }}
              >
                Learn More
              </a>
            </div>
          </div>
          <div className="navMob__drawer__amicaLogo">
            <img src={amicaLogo} alt="amica logo" />
          </div>
        </div>
      )}
    </div>
  );
};

export default NavbarMobile;
