import React from 'react';
import './PhotoGallerySingle.scss';

function PhotoGallerySingleMobile({ contents, swipe }) {
  return (
    <>
      <div className="galleryS__content__imgs--mobile">
        {contents.map((content, i) => {
          return (
            <div className="galleryS__content__imgs__img" key={i}>
              <div className="galleryS__content__imgs__img-container">
                <img alt="" src={content.img} className="galleryS__img" />
                <p className="galleryS__disclaimer">{content.disclaimer}</p>
              </div>
              <div className="galleryS__content__imgs__img-cap">
                <p className="galleryS__caption">{content.caption}</p>
              </div>
            </div>
          );
        })}
      </div>

      <p className="galleryS__swipe" ref={swipe}>
        SWIPE
        <span>
          <svg
            viewBox="0 0 5 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="galleryS__swipe__arrowhead"
          >
            <path
              d="M2.50416 5.05265L8.3412e-07 2.55959L0.53542 2.00015L4 5.4682L0.551159 8.92041L0.0160326 8.37686L2.53593 5.85159L2.88781 5.4682L2.50416 5.05265Z"
              fill="white"
            />
          </svg>
        </span>
      </p>
    </>
  );
}

export default PhotoGallerySingleMobile;
