import photo1 from '../../../assets/cameraCarousel1.jpg';
import photo2 from '../../../assets/cameraCarousel2.jpg';
import photo3 from '../../../assets/cameraCarousel3.jpg';
import photo4 from '../../../assets/cameraCarousel4.jpg';
import photo5 from '../../../assets/cameraCarousel5.jpg';
import photo6 from '../../../assets/cameraCarousel6.jpg';
import photo7 from '../../../assets/cameraCarousel7.jpg';

//Update the url or delete below arrowImg if importing the arrows at the top
const arrowImg = 'https://component-library-assets.s3.amazonaws.com/arrow.svg';

const galleryContents = {
  fadeAnimation: false, //true if adding transition animation
  arrows: arrowImg, //import or assign path/url at the top
  contents: [
    {
      img: photo1,
      caption:
        'Kwasi and his father in Brooklyn’s Prospect Park, shortly before the family’s move to Los Angeles.',
      disclaimer: ''
    },
    {
      img: photo2,
      caption:
        'For Kwasi, looking through the viewfinder of the camera he inherited often feels like a way to see the world through his father’s eyes.'
    },
    {
      img: photo3,
      caption:
        'Kwasi’s vintage Olympus Pen FT camera has a distinctive half-frame format that allows photographers to capture two images using only one frame of film.',
      disclaimer: ''
    },
    {
      img: photo4,
      caption:
        'Kwasi took his first photos in this Hollywood neighborhood. Today, his photos have reached a global audience in many of the world’s most prestigious museums and publications.'
    },
    {
      img: photo5,
      caption: 'Half-frame photograph of a Hollywood intersection courtesy of Kwasi Boyd-Bouldin.'
    },
    {
      img: photo6,
      caption: 'Half-frame self-portrait from Griffith Park courtesy of Kwasi Boyd-Bouldin.'
    },
    {
      img: photo7,
      caption: ''
    }
  ]
};

export default galleryContents;
