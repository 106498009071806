import anneAudio from '../../assets/audioFiles/anne.wav';
import rashunaAudio from '../../assets/audioFiles/rashuna.wav';
import laurenAudio from '../../assets/audioFiles/lauren.wav';
import rhondaAudio from '../../assets/audioFiles/rhonda.wav';
import johnAudio from '../../assets/audioFiles/john.wav';
import jonathonAudio from '../../assets/audioFiles/jonathon.wav';
import catharineAudio from '../../assets/audioFiles/catharine.wav';
import benAudio from '../../assets/audioFiles/bennett.wav';

const tileData = [
  {
    title: 'Flight Plan',
    person: 'Anne',
    audioFile: anneAudio,
    background: '',
    audioPlaying: false,
    videoURL: 'https://courageous-cdn-media-bucket.s3.amazonaws.com/amica/anne.mp4'
  },
  {
    title: 'Ring & Book',
    person: 'Rashuna',
    audioFile: rashunaAudio,
    background: '',
    videoURL: 'https://courageous-cdn-media-bucket.s3.amazonaws.com/amica/rashuna.mp4'
  },
  {
    title: 'Wedding Band',
    person: 'Lauren',
    audioFile: laurenAudio,
    background: '',
    audioPlaying: false,
    videoURL: 'https://courageous-cdn-media-bucket.s3.amazonaws.com/amica/lauren.mp4'
  },
  {
    title: 'WWII Uniform',
    person: 'Rhonda',
    audioFile: rhondaAudio,
    background: '',
    audioPlaying: false,
    videoURL: 'https://courageous-cdn-media-bucket.s3.amazonaws.com/amica/rhonda.mp4'
  },
  {
    title: 'Metal Spoon',
    person: 'John',
    audioFile: johnAudio,
    background: '',
    audioPlaying: false,
    videoURL: 'https://courageous-cdn-media-bucket.s3.amazonaws.com/amica/john.mp4'
  },

  {
    title: 'Family',
    person: 'Jonathon',
    audioFile: jonathonAudio,
    background: '',
    audioPlaying: false,
    videoURL: 'https://courageous-cdn-media-bucket.s3.amazonaws.com/amica/jonathon.mp4'
  },
  {
    title: 'Quote',
    person: 'Catharine',
    audioFile: catharineAudio,
    background: '',
    audioPlaying: false,
    videoURL: 'https://courageous-cdn-media-bucket.s3.amazonaws.com/amica/catherine.mp4'
  },
  {
    title: 'Cassette Tapes',
    person: 'Bennett',
    audioFile: benAudio,
    background: '',
    audioPlaying: false,
    videoURL: 'https://courageous-cdn-media-bucket.s3.amazonaws.com/amica/ben.mp4'
  }
];

export default tileData;
