import photo1 from '../../../assets/eventCarousel1.jpg';
import photo2 from '../../../assets/eventCarousel2.jpg';
import photo3 from '../../../assets/eventCarousel3.jpg';
import photo4 from '../../../assets/eventCarousel4.jpg';
import photo5 from '../../../assets/eventCarousel5.jpg';
import photo6 from '../../../assets/eventCarousel6.jpg';
import photo7 from '../../../assets/eventCarousel7.jpg';

//Update the url or delete below arrowImg if importing the arrows at the top
const arrowImg = 'https://component-library-assets.s3.amazonaws.com/arrow.svg';

const galleryContents = {
  fadeAnimation: false, //true if adding transition animation
  arrows: arrowImg, //import or assign path/url at the top
  contents: [
    {
      img: photo1,
      caption: '',
      disclaimer: ''
    },
    {
      img: photo2,
      caption: ''
    },
    {
      img: photo3,
      caption: '',
      disclaimer: ''
    },
    {
      img: photo4,
      caption: '',
      disclaimer: ''
    },
    {
      img: photo5,
      caption: ''
    },
    {
      img: photo6,
      caption: ''
    },
    {
      img: photo7,
      caption: ''
    }
  ]
};

export default galleryContents;
